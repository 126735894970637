<template>
    <div class="p-8 text-xs">
        <!-- <div class="flex justify-end mb-4"> -->
        <!-- <div class="flex justify-end mb-4"> -->
        <!-- Fullscreen Button - Hidden when in fullscreen mode -->
        <!-- <button @click="toggleDarkMode" class="flex items-center bg-transparent-500 text-black rounded">
                    <i :class="isDark ? 'fas fa-sun' : 'fas fa-moon'"></i>
                </button> -->

        <!-- Fullscreen Button - Hidden when in fullscreen mode -->
        <!-- <button v-if="!isFullscreen" @click="toggleFullScreen"
                    class="flex items-center bg-transparent-500 text-black rounded ml-4">
                    <i class="fas fa-expand mr-2"></i>
                </button> -->

        <!-- Refresh Button - Disabled and shows loading spinner when refreshing -->
        <!-- <button @click="refreshData" :disabled="isLoading"
                    class="flex items-center bg-transparent-500 text-black rounded ml-4">
                    <i :class="isLoading ? 'fas fa-spinner fa-spin' : 'fas fa-sync-alt'"></i>
                </button> -->

        <!-- <div class="border-l h-8 mx-4"></div> -->


        <!-- </div> -->
        <!-- </div> -->
        <div class="p-8 text-xs">
            <div class="w-full ml-4">
                <p class="text-5xl font-bold leading-6">
                    {{ hours > 9 ? hours : `0${hours}` }} : {{ minutes > 9 ? minutes : `0${minutes}` }} : {{ seconds
                        > 9 ? seconds : `0${seconds}` }}
                </p>
                <p class="text-gray-400 text-2xl font-medium mt-3">Jam Sekarang</p>
            </div>
            <!-- <div class="text-xs rounded-2xl" :class="isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'"> -->
            <div class="rounded-2xl p-5 grid grid-cols-2 xl:grid-cols-3 gap-10">
                <div v-for="item in data" :key="item.id" class="p-4 h-60 rounded-lg shadow-md text-center relative"
                    :class="isDark ? 'bg-gray-800 text-white' : 'bg-white'">
                    <div class="card-header flex justify-between">
                        <div class="flex items-center pb-2" :class="isDark ? 'text-white' : 'text-black-600'">
                            <div v-if="item.type == 'level'">
                                <img :src="item.image_url" alt="Siaga Level" class="rounded-lg w-6 h-6">
                            </div>
                            <div v-if="item.type == 'rain'">
                                <img src="/img/rain.svg" alt="Rain" class="rounded-lg w-6 h-6">
                            </div>
                            <div v-if="item.type == 'weather'">
                                <img src="/img/weather.svg" alt="Weather" class="rounded-lg w-6 h-6">
                            </div>
                            <div v-if="item.type == 'camera'">
                                <img src="/img/camera.svg" alt="Camera" class="rounded-lg w-6 h-6">
                            </div>
                            <div v-if="item.type == 'wavegauge'">
                                <img src="/img/wavebuoy.svg" alt="wavegauge" class="rounded-lg w-6 h-6">
                            </div>
                            <div v-if="item.type == 'wavebuoy'">
                                <img src="/img/wavebuoy.svg" alt="wavebuoy" class="rounded-lg w-6 h-6">
                            </div>
                            <h3 class="font-bold text-lg ml-2">{{ item.name }}</h3>
                        </div>
                        <div class="flex items-center pb-2" :class="isDark ? 'text-white' : 'text-black-600'">
                            <div v-if="item.type == 'level'">
                                <span>Water Level</span>
                            </div>
                            <div v-if="item.type == 'rain'">
                                <span>Rain</span>
                            </div>
                            <div v-if="item.type == 'weather'">
                                <span>Weather</span>
                            </div>
                            <div v-if="item.type == 'camera'">
                                <span>Timelapse</span>
                            </div>
                            <div v-if="item.type == 'wavegauge'">
                                <span>Wave Gauge</span>
                            </div>
                        </div>
                    </div>
                    <hr class="mt-2 mb-2">
                    <div class="flex justify-between items-center" :class="isDark ? 'text-white' : 'text-black-600'">
                        <div v-if="item.status_device == 'Aktif'">
                            <p class="text-xs mr-7" v-if="item.type == 'level'">
                                {{ parseFloat(item.real_ketinggian * 100).toFixed(2) }} cm
                            </p>
                            <p class="text-xs" v-else-if="item.type == 'WQ'">
                                {{ parseFloat(item.pH).toFixed(2) }}
                            </p>
                            <p class="text-xs" v-else-if="item.type == 'rain' || item.type == 'weather'">
                                {{ parseFloat(item.precipitation).toFixed(2) }} mm
                            </p>
                            <p class="text-xs">{{ formatTimeAgo(item.last_update) }}</p>
                        </div>
                        <div v-if="item.status_device == 'Aktif'">
                            <div class="card">
                                <div class="card-body flex items-center justify-between p-1 rounded-lg shadow-lg"
                                    :class="isDark ? 'bg-gray-700' : 'bg-white'">
                                    <!-- Battery Section -->
                                    <div v-if="item.status_device == 'Aktif'"
                                        class="battery-section flex items-center mr-4">
                                        <div v-if="item.persentase_battery >= 80" class="flex items-center">
                                            <span class="font-semibold text-xs mr-2">{{ item.persentase_battery
                                            }}%</span>
                                            <img src="/img/100.svg" alt="Battery 100%" />
                                        </div>
                                        <div v-if="item.persentase_battery < 80 && item.persentase_battery > 50"
                                            class="flex items-center">
                                            <span class="font-semibold text-xs mr-2">{{ item.persentase_battery
                                            }}%</span>
                                            <img src="/img/75.svg" alt="Battery 75%" />
                                        </div>
                                        <div v-if="item.persentase_battery <= 50 && item.persentase_battery >= 40"
                                            class="flex items-center">
                                            <span class="font-semibold text-xs mr-2">{{ item.persentase_battery
                                            }}%</span>
                                            <img src="/img/50.svg" alt="Battery 50%" />
                                        </div>
                                        <div v-if="item.persentase_battery < 40 && item.persentase_battery > 0"
                                            class="flex items-center">
                                            <span class="font-semibold text-xs mr-2">{{ item.persentase_battery
                                            }}%</span>
                                            <img src="/img/25.svg" alt="Battery 25%" />
                                        </div>
                                        <div v-if="item.persentase_battery <= 0" class="flex items-center">
                                            <span class="font-semibold text-xs mr-2">{{ item.persentase_battery
                                            }}%</span>
                                            <img src="/img/0.svg" alt="Battery 0%" />
                                        </div>
                                    </div>

                                    <!-- Signal Section -->
                                    <div v-if="item.status_device == 'Aktif'" class="signal-section flex items-center">
                                        <div v-if="item.CSQ >= 41 && item.CSQ <= 60" class="flex items-center">
                                            <span class="font-semibold text-xs mr-2">Good</span>
                                            <img src="/img/signal_100.png" />
                                        </div>
                                        <div v-if="item.CSQ >= 21 && item.CSQ <= 40" class="flex items-center">
                                            <span class="font-semibold text-xs mr-2">Medium</span>
                                            <img src="/img/signal_50.png" />
                                        </div>
                                        <div v-if="item.CSQ >= 0 && item.CSQ <= 20" class="flex items-center">
                                            <span class="font-semibold text-xs mr-2">Low</span>
                                            <img src="/img/signal_0.png" />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div v-if="item.status_device === 'Mati'"
                            class="w-full flex flex-col items-center justify-center text-center p-4"
                            :class="isDark ? 'text-white' : 'text-black-600'">
                            <!-- Icon Section -->
                            <div class="h-12 w-12 bg-red-200 rounded-full flex items-center justify-center mb-2">
                                <i class="fas fa-exclamation-triangle text-red-600 text-2xl"></i>
                            </div>

                            <!-- Text Section -->
                            <p class="text-sm font-semibold">Sensor Mati</p>
                            <p class="text-xs">Data tidak terkirim. Hubungi Dukungan Pelanggan.</p>
                        </div>
                    </div>
                    <div v-if="item.status_device == 'Aktif'">
                        <div class="flex flex-col items-center">
                            <div id="chart" class="w-full">
                                <apexchart v-if="item.status_device !== 'Mati'" type="area" height="100"
                                    :class="isDark ? 'text-white' : 'text-black-600'" :options="chartOptions"
                                    :series="series">
                                </apexchart>
                            </div>
                            <!-- Button Section -->
                            <div id="button" class="w-full" v-if="!isFullscreen">
                                <button @click="openDetailModal(item)" :class="isDark ? 'text-white' : 'text-black-600'"
                                    class="mt-0 bg-blue-500 text-white py-2 w-full rounded-full text-xs flex items-center justify-center">
                                    <i class="fas fa-info-circle mr-2"></i> Detail
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <!-- </div> -->

            <transition name="modal-fade">
                <div v-if="isDetailModalOpen" @click.self="closeModal"
                    class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div :class="isDark ? 'bg-gray-800' : 'bg-white'"
                        class="bg-white p-6 rounded-lg shadow-lg w-full max-w-xl relative">
                        <!-- Close Button -->
                        <button @click="closeModal" class="absolute top-2 right-2 text-gray-500 hover:text-gray-800">
                            <i class="fas fa-times"></i>
                        </button>

                        <!-- Header -->
                        <span class="text-xs text-gray-400 mb-2">{{ selectedItem.code }}</span>
                        <!-- <p class="text-2xl font-bold" >{{ selectedItem.name }}</p> -->
                        <div :class="isDark ? 'bg-gray-800 text-white' : 'bg-white text-black'">
                            <p class="text-2xl font-bold">{{ selectedItem.name }}</p>
                        </div>

                        <div class="flex space-x-4 my-3">
                            <button :class="isDark ? 'bg-gray-700 text-gray-300' : 'bg-blue-200 text-blue-700'"
                                class="px-4 py-1 rounded-full flex items-center space-x-2">
                                <i class="fas" :class="{
                                    'fa-cloud-rain': selectedItem.type === 'rain',
                                    'fa-sun': selectedItem.type === 'weather',
                                    'fa-camera': selectedItem.type === 'camera',
                                    'fa-water': selectedItem.type === 'wavegauge',
                                    'fa-solid fa-arrow-up-from-water-pump': selectedItem.type === 'level'
                                }"></i>
                                <span>{{ selectedItem.type }}</span>
                            </button>

                            <button
                                :class="[selectedItem.status_device == 'Aktif' ? (isDark ? 'bg-green-800 text-green-300' : 'bg-green-200 text-green-700') : (isDark ? 'bg-red-800 text-red-300' : 'bg-red-200 text-red-700')]"
                                class="px-4 py-1 rounded-full flex items-center space-x-2">
                                <i class="fas fa-circle"></i>
                                <span>{{ selectedItem.status_device }}</span>
                            </button>
                        </div>


                        <!-- Data Grid -->
                        <div class="grid grid-cols-2 gap-4 my-4">
                            <!-- Example data showing the format -->
                            <div
                                :class="isDark ? 'p-4 bg-gray-800 text-white rounded-lg shadow-lg w-full text-center border border-gray-700' : 'p-4 bg-white text-black rounded-lg shadow-lg w-full text-center border border-gray-300'">
                                <p class="font-bold text-lg">
                                    <!-- Menampilkan ketinggian air atau data sesuai tipe item -->
                                    <span v-if="selectedItem.type == 'level'">
                                        {{ parseFloat(selectedItem.real_ketinggian * 100).toFixed(2) }} cm
                                    </span>
                                    <span v-else-if="selectedItem.type == 'WQ'">
                                        {{ parseFloat(selectedItem.pH).toFixed(2) }}
                                    </span>
                                    <span v-else-if="selectedItem.type == 'rain' || selectedItem.type == 'weather'">
                                        {{ parseFloat(selectedItem.precipitation).toFixed(2) }} mm
                                    </span>
                                </p>

                                <!-- Bagian deskripsi item -->
                                <div class="flex items-center justify-center space-x-1"
                                    :class="isDark ? 'text-gray-300' : 'text-gray-500'">
                                    <!-- Ikon yang relevan berdasarkan tipe item -->
                                    <i class="fas" :class="{
                                        'fa-water': selectedItem.type == 'level',  // Ikon air jika tipe 'level'
                                        'fa-flask': selectedItem.type == 'WQ',    // Ikon lab/pH jika tipe 'WQ'
                                        'fa-cloud-rain': selectedItem.type == 'rain' || selectedItem.type == 'weather'  // Ikon hujan jika tipe 'rain' atau 'weather'
                                    }"></i>

                                    <!-- Label yang relevan berdasarkan tipe item -->
                                    <p class="text-sm">
                                        <span v-if="selectedItem.type == 'level'">Ketinggian Air</span>
                                        <span v-else-if="selectedItem.type == 'WQ'">Kualitas Air (pH)</span>
                                        <span
                                            v-else-if="selectedItem.type == 'rain' || selectedItem.type == 'weather'">Curah
                                            Hujan</span>
                                    </p>
                                </div>

                                <!-- Bagian waktu update -->
                                <div
                                    :class="isDark ? 'p-2 bg-gray-700 text-gray-400 rounded-b-lg mt-4' : 'p-2 bg-gray-50 text-gray-400 rounded-b-lg mt-4'">
                                    <p class="text-xs">
                                        <i class="far fa-clock"></i> {{ formatTimeAgo(selectedItem.last_update) }}
                                    </p>
                                </div>
                            </div>


                            <div
                                :class="isDark ? 'p-4 bg-gray-800 text-white rounded-lg shadow-lg w-full text-center border border-gray-700' : 'p-4 bg-white text-black rounded-lg shadow-lg w-full text-center border border-gray-300'">
                                <!-- Menampilkan CSQ dalam bentuk persentase dan status sinyal (Good, Medium, dll.) -->
                                <p class="font-bold text-lg">
                                    {{ selectedItem.CSQ >= 75 ? 'Good' : selectedItem.CSQ >= 50 ? 'Medium' :
                                        selectedItem.CSQ >= 25 ? 'Low' : 'Critical' }}
                                </p>
                                <div class="flex items-center justify-center space-x-1"
                                    :class="isDark ? 'text-gray-300' : 'text-gray-500'">
                                    <!-- Menampilkan ikon sinyal sesuai kondisi CSQ -->
                                    <i class="fas fa-signal"></i>
                                    <p class="text-sm">Sinyal GSM</p>
                                </div>
                                <div
                                    :class="isDark ? 'p-2 bg-gray-700 text-gray-400 rounded-b-lg mt-4' : 'p-2 bg-gray-50 text-gray-400 rounded-b-lg mt-4'">
                                    <p class="text-xs">
                                        <i class="far fa-clock"></i> {{ formatTimeAgo(selectedItem.last_update) }}
                                    </p>
                                </div>
                            </div>
                            <!-- Tampilkan data lain sesuai kebutuhan -->
                            <div
                                :class="isDark ? 'p-4 bg-gray-800 text-white rounded-lg shadow-lg w-full text-center border border-gray-700' : 'p-4 bg-white text-black rounded-lg shadow-lg w-full text-center border border-gray-300'">
                                <p class="font-bold text-lg">{{ selectedItem.persentase_battery }}%</p>
                                <div class="flex items-center justify-center space-x-1"
                                    :class="isDark ? 'text-gray-300' : 'text-gray-500'">
                                    <i class="fas fa-battery-full mr-1"></i>
                                    <p class="text-sm">Daya Baterai</p>
                                </div>
                                <div
                                    :class="isDark ? 'p-2 bg-gray-700 text-gray-400 rounded-b-lg mt-4' : 'p-2 bg-gray-50 text-gray-400 rounded-b-lg mt-4'">
                                    <p class="text-xs">
                                        <i class="far fa-clock"></i> {{ formatTimeAgo(selectedItem.last_update) }}
                                    </p>
                                </div>
                            </div>

                            <div
                                :class="isDark ? 'p-4 bg-gray-800 text-white rounded-lg shadow-lg w-full text-center border border-gray-700' : 'p-4 bg-white text-black rounded-lg shadow-lg w-full text-center border border-gray-300'">
                                <!-- Menampilkan persentase kelembapan -->
                                <p class="font-bold text-lg">{{ parseFloat(selectedItem.HUM).toFixed(0) }} % Kelembapan
                                </p>

                                <!-- Menampilkan ikon termometer dan label kelembapan -->
                                <div class="flex items-center justify-center space-x-1"
                                    :class="isDark ? 'text-gray-300' : 'text-gray-500'">
                                    <i class="fas fa-thermometer-half"></i>
                                    <p class="text-sm">Kelembapan</p>
                                </div>

                                <!-- Menampilkan waktu update -->
                                <div
                                    :class="isDark ? 'p-2 bg-gray-700 text-gray-400 rounded-b-lg mt-4' : 'p-2 bg-gray-50 text-gray-400 rounded-b-lg mt-4'">
                                    <p class="text-xs">
                                        <i class="far fa-clock"></i> {{ formatTimeAgo(selectedItem.last_update) }}
                                    </p>
                                </div>
                            </div>


                            <div
                                :class="isDark ? 'p-4 bg-gray-800 text-white rounded-lg shadow-lg w-full text-center border border-gray-700' : 'p-4 bg-white text-black rounded-lg shadow-lg w-full text-center border border-gray-300'">
                                <!-- Menampilkan Voltase Solar Panel -->
                                <p class="font-bold text-lg">{{ parseFloat(selectedItem.VSOL).toFixed(2) }} V</p>

                                <!-- Menampilkan ikon solar panel dan label voltase solar panel -->
                                <div class="flex items-center justify-center space-x-1"
                                    :class="isDark ? 'text-gray-300' : 'text-gray-500'">
                                    <i class="fas fa-solar-panel"></i>
                                    <p class="text-sm">Voltase Solar Panel</p>
                                </div>

                                <!-- Menampilkan waktu update -->
                                <div
                                    :class="isDark ? 'p-2 bg-gray-700 text-gray-400 rounded-b-lg mt-4' : 'p-2 bg-gray-50 text-gray-400 rounded-b-lg mt-4'">
                                    <p class="text-xs">
                                        <i class="far fa-clock"></i> {{ formatTimeAgo(selectedItem.last_update) }}
                                    </p>
                                </div>
                            </div>

                            <div
                                :class="isDark ? 'p-4 bg-gray-800 text-white rounded-lg shadow-lg w-full text-center border border-gray-700' : 'p-4 bg-white text-black rounded-lg shadow-lg w-full text-center border border-gray-300'">
                                <!-- Menampilkan Tekanan Atmosfer -->
                                <p class="font-bold text-lg">{{ parseFloat(selectedItem.PATM).toFixed(0) }} kPa</p>

                                <!-- Menampilkan ikon barometer dan label Tekanan Atmosfer -->
                                <div class="flex items-center justify-center space-x-1"
                                    :class="isDark ? 'text-gray-300' : 'text-gray-500'">
                                    <i class="fas fa-tachometer-alt"></i>
                                    <p class="text-sm">Tekanan Atmosfer</p>
                                </div>

                                <!-- Menampilkan waktu update -->
                                <div
                                    :class="isDark ? 'p-2 bg-gray-700 text-gray-400 rounded-b-lg mt-4' : 'p-2 bg-gray-50 text-gray-400 rounded-b-lg mt-4'">
                                    <p class="text-xs">
                                        <i class="far fa-clock"></i> {{ formatTimeAgo(selectedItem.last_update) }}
                                    </p>
                                </div>
                            </div>

                        </div>
                        <!-- Footer with Detail Button -->
                        <div class="text-center mt-4">
                            <button :class="isDark ? 'bg-gray-700 text-white' : 'bg-blue-500 text-white'"
                                class="py-2 px-6 rounded-full" @click="goDetail(selectedItem)">Lihat Detail</button>
                        </div>

                    </div>
                </div>
            </transition>

            <!-- Map component -->
            <div class="col-span-12 md:col-span-8 h-96 w-full z-30">
                <mapData v-if="maker.length" :device="device" :maker="maker" ref="myMapCC" class="rounded-l-2xl " />
            </div>
        </div>
    </div>
</template>

<style scoped>
.signal-green {
    filter: hue-rotate(90deg) saturate(100%);
}

.signal-lightgreen {
    filter: hue-rotate(60deg) saturate(80%);
}

.signal-yellow {
    filter: hue-rotate(30deg) saturate(50%);
}

.signal-orange {
    filter: hue-rotate(15deg) saturate(25%);
}

.signal-red {
    filter: hue-rotate(0deg) saturate(0%);
}

.dark-mode {
    background-color: white;
    color: white;
}

.dark-mode .text-black {
    color: black;
}

.dark-mode .bg-white-100 {
    background-color: #333;
}

modal-fade-enter-active,
.modal-fade-leave-active {
    transition: opacity 0.5s;
}

.modal-fade-enter,
.modal-fade-leave-to

/* .modal-fade-leave-active in <2.1.8 */
    {
    opacity: 0;
}

.modal-enter-active,
.modal-leave-active {
    transition: opacity 0.5s;
}

.modal-enter,
.modal-leave-to {
    opacity: 0;
}
</style>

<script>
import { mapGetters, mapAction, mapActions } from 'vuex'
import { MglMap, MglMarker } from 'vue-mapbox';
import mapboxgl from 'mapbox-gl';
// import mapData from '../map.vue'
import mapData from '../../components/mapCC.vue'
import { latLng } from 'leaflet';

import VueApexCharts from "vue-apexcharts";
import { ValidationProvider, ValidationObserver } from "vee-validate/dist/vee-validate.full";
import globalMixin from '../../mixin/global'
import deleteComponent from '../../components/global/deleteComponent.vue'
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

// Extend dayjs with the relativeTime plugin
dayjs.extend(relativeTime);


export default {
    data: () => ({
        data: [],
        maker: [],
        currentPage: 1,
        limit: 100, // Jumlah item per halaman
        totalRows: 0,
        startNumber: 0,
        endNumber: 0,
        totalPages: 0,
        keyword: '',
        currentDevice: null, // Example of setting device locally
        isDetailModalOpen: false,
        selectedItem: null,
        isLoading: false,
        isDark: false, // Default tidak dalam Dark Mode
        hours: new Date().getHours(),
        minutes: new Date().getMinutes(),
        seconds: new Date().getSeconds(),
        tipe_perangkat: '',
        customer: null,
        config: {}, // konfigurasi axios
        tipe_perangkat: '',
        currentPage: 1,
        disabled: false,
        modal_dialog: false,
        keyword: '',
        foto: '',
        delete_id: '',
        modal_edit: false,
        default_url: process.env.VUE_APP_IMAGES,
        refresh: false,
        showMap: false,
        currentMap: null,
        openDropdownId: null,
        accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
        mapStyle: 'mapbox://styles/mapbox/streets-v11',
        longitude: 106.827153,
        latitude: -6.175110,
        isFullscreen: false,
        series: [{
            name: 'Ketinggian Air',
            data: [190, 192, 194, 195, 196, 198, 200]  // Contoh data selama 30 menit terakhir
        }],
        chartOptions: {
            chart: {
                type: 'area',
                height: 350,
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            // title: {
            //     text: 'Perubahan Ketinggian Air Selama 30 Menit',
            //     align: 'left'
            // },
            labels: [

            ], // Waktu setiap 5 menit dalam 30 menit terakhir
            xaxis: {
                type: 'category',
                show: false,
                labels: {
                    show: false,
                },
            },
            yaxis: {
                opposite: true,
                show: false,
                labels: {
                    show: false,
                },
            },
            legend: {
                horizontalAlign: 'left'
            }
        },
    }),
    props: {
        isDark: {
            type: Boolean,
            required: true,
        },
    },
    mixins: [globalMixin],
    methods: {
        ...mapActions(['setCurrentDevice']),
        setCurrentDevice(device) {
            this.currentDevice = device; // Local method
        },
        getData() {
            // Check if currentDevice exists, and call the appropriate API endpoint
            let customer_id = this.customer ? this.customer.id : '';
            let url = this.currentDevice && this.currentDevice.id
                ? `v2/device/detail/data/${this.currentDevice.id}`
                : `v1/device?page=${this.currentPage}&limit=${this.limit}&keyword=${this.keyword}&status=1&customer_id=${customer_id}&tipe_perangkat=${this.tipe_perangkat}`;

            // Fetch data from the appropriate endpoint
            this.axios.get(url, this.config)
                .then((ress) => {
                    this.data = ress.data.rows;
                    this.totalRows = ress.data.count || this.data.length;  // Handle different response formats

                    // Pagination logic for the list (if not fetching by currentDevice)
                    if (!this.currentDevice) {
                        this.totalPages = Math.ceil(this.totalRows / this.limit);

                        // Logic for setting the pagination boundaries
                        if (this.totalRows <= this.limit) {
                            this.endNumber = this.totalRows;
                        } else if (this.currentPage > 1) {
                            let page = this.currentPage - 1;
                            this.startNumber = page * this.limit + 1;
                            this.endNumber = this.currentPage * this.limit;
                            if (this.totalRows < this.endNumber) {
                                this.endNumber = this.totalRows;
                            }
                        } else {
                            this.startNumber = 1;
                            this.endNumber = this.limit;
                        }
                        this.startNumber = this.totalRows < 1 ? 0 : (this.currentPage - 1) * this.limit + 1;
                    }

                    // Assign image URLs based on device type and condition
                    this.data.forEach(element => {
                        if (element.type == 'WQ') {
                            element.image_url = '/img/map_wq.svg';
                        } else if (element.type == 'level') {
                            if (element.siaga_level == 1) {
                                element.image_url = '/img/map_siaga1.svg';
                            } else if (element.siaga_level == 2) {
                                element.image_url = '/img/map_siaga2.svg';
                            } else if (element.siaga_level == 3) {
                                element.image_url = '/img/map_siaga3.svg';
                            } else if (element.siaga_level == 4) {
                                element.image_url = '/img/map_siaga4.svg';
                            }
                        } else if (element.type == 'rain') {
                            element.image_url = '/img/rain_map.svg';
                        } else if (element.type == 'weather') {
                            element.image_url = '/img/weather_map.svg';
                        }
                    });
                })
                .catch((err) => {
                    this.errorResponse(err);
                });
        },
        async getDataCurrentDevice() {
            await this.axios.get('v2/device/detail/data/' + this.currentDevice.id, this.config)
                .then((ress) => {
                    this.data = ress.data.rows;
                    console.log();
                })
                .catch((err) => {
                    this.errorResponse(err)
                })
        },

        goDetail() {
            if (this.selectedItem) {
                this.$store.dispatch('device/setCurrentDevice', this.selectedItem); // Simpan di Vuex
                this.$router.push('/monitor/detail');
            } else {
                console.error('selectedItem is not defined');
            }
        },
        closeModal() {
            this.isDetailModalOpen = false; // Tutup modal
            this.selectedItem = null; // Reset item yang dipilih
        },
        openDetailModal(item) {
            this.selectedItem = item;
            this.isDetailModalOpen = true;
        },
        toggleDarkMode() {
            this.isDark = !this.isDark;
            if (this.isDark) {
                document.documentElement.classList.add('dark-mode');
            } else {
                document.documentElement.classList.remove('dark-mode');
            }
        },
        nextPage() {
            if (this.currentPage * this.limit < this.totalRows) {
                this.currentPage++;
                this.getData();
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.getData();
            }
        },
        formatTimeAgo(date) {
            return dayjs(date).fromNow(); // Format time to 'x minutes ago'
        },
        setMapzoom(item) {
            this.currentMap = item;
            this.$nextTick(() => {
                if (this.$refs.myMap) {
                    this.$refs.myMap.flyToMarker(item.latitude, item.longitude);
                } else {
                    console.error('myMap is not defined');
                }
            });
        },
        refres() {
            this.refresh = true;
            this.axios.get('v1/device', this.config)
                .then((ress) => {

                    this.refresh = false;
                    this.$snack.success({
                        text: ress.data.message,
                    })
                })
                .catch((err) => {
                    this.refresh = false;
                    this.$snack.error({
                        text: ress.data.message,
                    })

                    console.log(err.response.data.message)
                })
        },
        toggleFullScreen() {
            const elem = document.documentElement;
            if (!document.fullscreenElement) {
                this.isFullscreen = true;
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.mozRequestFullScreen) {
                    elem.mozRequestFullScreen();
                } else if (elem.webkitRequestFullscreen) {
                    elem.webkitRequestFullscreen();
                } else if (elem.msRequestFullscreen) {
                    elem.msRequestFullscreen();
                }
            } else {
                this.isFullscreen = false;
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                }
            }
        },
        refreshData() {
            this.isLoading = true;
            // Simulate data fetch or page reload
            setTimeout(() => {
                location.reload(); // This will reload the entire page
                this.isLoading = false; // You can omit this line since the page reloads
            }, 1000); // Simulating a delay before refresh
        },
        getDevice() {
            this.axios.get('v1/device?status=1', this.config)
                .then(async (ress) => {
                    this.device = ress.data.rows
                    await this.device.forEach(element => {
                        let image_url = ''

                        if (element.type == 'WQ') {
                            image_url = '/img/map_wq.svg'
                        } else if (element.type == 'level') {
                            if (element.siaga_level == 1) {
                                image_url = '/img/map_siaga1.svg'
                            }
                            if (element.siaga_level == 2) {
                                image_url = '/img/map_siaga2.svg'
                            }
                            if (element.siaga_level == 3) {
                                image_url = '/img/map_siaga3.svg'
                            }
                            if (element.siaga_level == 4) {
                                image_url = '/img/map_siaga4.svg'
                            }
                        } else if (element.type == 'rain') {
                            image_url = '/img/rain_map.svg'
                        } else if (element.type == 'weather') {
                            image_url = '/img/weather_map.svg'
                        }

                        this.maker.push({
                            lokasi: latLng(element.latitude, element.longitude), name: element.name, level: element.level,
                            last_update: element.last_update,
                            siaga_1: element.siaga_1,
                            siaga_2: element.siaga_2,
                            siaga_3: element.siaga_3,
                            siaga_4: element.siaga_4,
                            real_ketinggian: element.real_ketinggian,
                            debit: element.debit,
                            elevasi_muka_air: element.elevasi_muka_air,
                            type: element.type,
                            pH: element.pH,
                            id: element.id,
                            icon_map: image_url
                        })
                    });
                })
                .catch((err) => {
                    console.log(err)
                    if (err.response) {
                        this.errorResponse(err)

                    }
                })
        },

    },
    mounted() {
        // Set interval to update time every second
        this.timeInterval = setInterval(() => {
            const now = new Date();
            this.hours = now.getHours();
            this.minutes = now.getMinutes();
            this.seconds = now.getSeconds();
        }, 1000);
    },
    beforeDestroy() {
        // Clear interval when component is destroyed to prevent memory leaks
        clearInterval(this.timeInterval);
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            currentDevice: 'device/currentDevice'
        }),

    },
    created() {
        this.getData(),
            this.getDataCurrentDevice(),
            this.getDevice()
    },
    components: {
        deleteComponent,
        apexchart: VueApexCharts,
        mapData
    }
}
</script>